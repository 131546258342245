import React, {useCallback, useEffect, useRef, useState} from "react";
import {CamControls, Wrapper} from "./Camera.styles";
import Webcam from "react-webcam";
import CircleButton from "../../widgets/AddIcon";
import {FaCamera, FaCheck} from "react-icons/fa";
import {FaCameraRotate, FaTrashCan} from "react-icons/fa6";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const Camera = (props) => {

    const webcamRef = useRef(null); // create a webcam reference
    const mediaRecorderRef = React.useRef(null);
    const [recordedChunks, setRecordedChunks] = React.useState([]);
    const [imgSrc, setImgSrc] = useState(null);
    const [mirrored, setMirrored] = useState(false);
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
    const [isVideo, setIsVideo] = useState(props.isVideo);
    const [capturing, setCapturing] = React.useState(false);

    const changeCamera = useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
    }, [webcamRef]);

    const retake = () => {
        setImgSrc(null);
    };

    const handleStartCaptureClick = React.useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm"
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        console.log("handleStartCaptureClick", mediaRecorderRef.current);
        mediaRecorderRef.current.start();
        console.log("handleStartCaptureClick after", mediaRecorderRef.current);
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = React.useCallback(
        ({data}) => {
            console.log("handleDataAviable", data);
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
                console.log("handleDataAviable after", recordedChunks);
            }
        },
        [setRecordedChunks]
    );

    useEffect(() => {
        console.log("handleDataAviable updated", recordedChunks);
        saveVideo();
    }, [recordedChunks]);

    const handleStopCaptureClick = React.useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    function randomString(length) {
        return Math.random().toString(36).substring(2, 2 + length);
    }

    const saveVideo = React.useCallback(() => {
        console.log("saveVideo: ", recordedChunks);
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm"
            });
            setRecordedChunks([]);
            var file = new File([blob], `${randomString(12)}.webm`, {lastModified: Date.now()});
            props.saveVideo(file);
        }
    }, [recordedChunks, props]);

    return (
        <Wrapper>
            {imgSrc ? (
                <img src={imgSrc} alt="webcam"/>
            ) : (
                <Webcam
                    audio={false}
                    className="webcam"
                    ref={webcamRef}
                    mirrored={mirrored}
                    // screenshotFormat="image/jpeg"
                    videoConstraints={{
                        facingMode
                    }}
                />
            )}
            <CamControls>
                {imgSrc ? (
                    <>
                        <CircleButton onClick={retake}>
                            <FaTrashCan/>
                        </CircleButton>

                        <CircleButton onClick={() => {
                            props.saveImage(imgSrc);
                        }}>
                            <FaCheck/>
                        </CircleButton>
                    </>
                ) : (
                    <>
                        <div>
                            <input
                                type="checkbox"
                                checked={mirrored}
                                onChange={(e) => setMirrored(e.target.checked)}
                            />
                            <label>Mirror</label>
                        </div>
                        {isVideo ? (
                            <>
                                {capturing ? (
                                    <CircleButton onClick={handleStopCaptureClick}>
                                        Stop Capture
                                    </CircleButton>
                                ) : (
                                    <CircleButton onClick={handleStartCaptureClick}>
                                        Start Capture
                                    </CircleButton>
                                )}
                            </>
                        ) : (
                            <CircleButton onClick={capture}>
                                <FaCamera/>
                            </CircleButton>
                        )}
                        <CircleButton onClick={changeCamera}>
                            <FaCameraRotate/>
                        </CircleButton>
                    </ >
                )}
            </CamControls>
        </Wrapper>
    )
};

export default Camera;