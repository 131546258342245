import styled from "styled-components";

export const Wrapper = styled.div`
    margin-top: 44px;
    text-align: center;
`;

export const OptionsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 24px;

    img {
        cursor: pointer;
    }
`;