import React from "react";
import { Wrapper, OptionsContainer } from "./SignOptions.styles";
import GoogleIcon from "../../assets/Google.svg";
import FacebookIcon from "../../assets/Facebook.svg";
import AppleIcon from "../../assets/Apple.svg";

const SignOptions = ({ signIn }) => {

    return (
        <Wrapper>
            {signIn ?
            <p>or Sign in with</p>
            :
            <p>or Sign up with</p>
            }
            <OptionsContainer>
                <img src={GoogleIcon} alt="Log in with Google" />
                <img src={FacebookIcon} alt="Log in with Facebook" />
                <img src={AppleIcon} alt="Log in with Apple" />
            </OptionsContainer>
        </Wrapper>
    )
};

export default SignOptions;