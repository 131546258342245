import React, {useCallback, useEffect, useState} from "react";
import {ListOfPdf, PdfContainer} from "./PdfList.styles";
import {useNavigate, useParams} from 'react-router-dom';
import api from "../../utils/api";
import toast from "react-hot-toast";
import {Header} from "../../pages/PatientCabinet/styles";
import {IconContext} from "react-icons";
import {FaArrowLeft} from "react-icons/fa";
import {GeneralBody} from "../../widgets/GeneralBody";

const PdfList = () => {
    const navigate = useNavigate();
    const {patientId} = useParams();
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [reports, setReports] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [error, setError] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('report', file);
        try {
            const response = await api.post(`/patients/${patientId}/reports`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status !== 200) {
                toast.error('Failed to upload report');
                throw new Error('Failed to upload report');
            }
            toast.success('Report uploaded successfully');

            setMessage('Report uploaded successfully');
            fetchReports(); // Refresh the list of reports
        } catch (error) {
            setMessage(error.message);
        }
    };

    const fetchReports = useCallback(async () => {
        try {
            const response = await api.get(`/patients/${patientId}/reports`);

            if (response.status !== 200) {
                toast.error('Failed to fetch reports');
                throw new Error('Failed to fetch reports');
            }
            toast.success('Reports fetched successfully');

            const data = await response.data;
            setReports(data);
        } catch (error) {
            setError(error.message);
        }
    }, [patientId]);

    useEffect(() => {
        if (patientId) {
            fetchReports();
        }
    }, [patientId, fetchReports]);

    const handleReportClick = async (reportId) => {
        try {
            const response = await api.get(`/patients/${patientId}/reports/${reportId}`, {
                responseType: 'arraybuffer',
            });

            if (response.status !== 200) {
                toast.error('Failed to fetch report');
                throw new Error('Failed to fetch report');
            }

            console.log(response.data);

            const blob = new Blob([response.data], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            console.log(url);
            setSelectedReport(url);
            const pdfWindow = window.open();
            pdfWindow.location.href = selectedReport;
        } catch (error) {
            setError(error.message);
        }
    };

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <Header>
                <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
                    <FaArrowLeft onClick={() => navigate(-1)}/>
                </IconContext.Provider>
                <h3>Glacies Skin Report</h3>
            </Header>
            {/*{selectedReport && <iframe src={selectedReport} title='Report' width="100%" height="80%"/>}*/}
            <GeneralBody>
                <form onSubmit={handleSubmit}>
                    {message && <p style={{color: "red"}}>{message}</p>}
                    <input type="file" accept=".pdf" onChange={handleFileChange} required/>
                    <button type="submit" className="btn btn-4">Upload PDF report</button>
                </form>
                <ListOfPdf>
                    {reports.map((report, index) => (
                        <PdfContainer key={index} onClick={() => handleReportClick(report.id)}>
                            <p>Report {index + 1}</p>
                        </PdfContainer>
                    ))}
                </ListOfPdf>
            </GeneralBody>
        </>
    )
};

export default PdfList;
