import styled from "styled-components";

export const GeneralBody = styled.div`
    margin-top: 2rem;

    form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        margin: 0 auto;

        label {
            font-size: 1.5rem;
        }

        //
        //input {
        //    font-size: 1.5rem;
        //    padding: 0.5rem;
        //    border-radius: 0.5rem;
        //    border: 1px solid #000;
        //}
        /* file upload button */

        input[type="file"]::file-selector-button {
            border-radius: 4px;
            padding: 0 16px;
            height: 40px;
            cursor: pointer;
            background-color: white;
            border: 1px solid rgba(0, 0, 0, 0.16);
            box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
            margin-right: 16px;
            transition: background-color 200ms;
        }

        .uploadGroup {
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: space-between;
        }

        .btn {
            width: auto !important;
            max-width: 300px;
            padding: 0 !important;
        }

        /* file upload button hover state */

        input[type="file"]::file-selector-button:hover {
            background-color: #f3f4f6;
        }

        /* file upload button active state */

        input[type="file"]::file-selector-button:active {
            background-color: #e5e7eb;
        }

        button {
            margin-top: 0
        }

        progress {
            width: 100%;
            height: 2rem;
        }
    }
`;

export const DivRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: ${({alignItems}) => alignItems || 'center'};
    justify-content: ${({justifyContent}) => justifyContent || 'space-between'};
    gap: 1rem;
    margin-top: 1rem;
    max-width: ${({maxWidth}) => maxWidth}px;
    overflow-y: auto;
`;
