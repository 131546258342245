import React from 'react';

// Components
import { Wrapper, DashboardWrapper, DashboardInner } from '../components/DashboardWrapper/DashboardWrapper.styles';
import SideMenu from '../components/SideMenu';
import DashboardHeader from '../components/DashboardHeader';
import PatientList from '../components/PatientList';

const PatientListPage = () => {

    return (
        <DashboardWrapper>
            <DashboardHeader />
            <DashboardInner>
                <PatientList />
            </DashboardInner>
        </DashboardWrapper>
    );

}

export default PatientListPage;
