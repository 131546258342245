import api from "../api";

export const login = async (email, password) => {
    try {
        const response = await api.post('/login', {email, password}, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        if (!(response.status === 200)) {
            throw new Error('Invalid credentials, please try again!');
        }

        const data = await response.data;

        if (data) {
            localStorage.setItem('isAuthenticated', 'true');
            localStorage.setItem('user', JSON.stringify({
                id: data.id,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                role: data.role
            }));
            return {
                id: data.id,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                role: data.role
            };
        } else {
            throw new Error('Invalid credentials, please try again!');
        }
    } catch (error) {
        throw error.message;
    }
};


export const register = async (firstName, lastName, email, password, userRole) => {
    try {
        const response = await api.post('/register',
            {
                firstName,
                lastName,
                email,
                password,
                userRole,
            },
            {}
        );

        if (!(response.status === 201)) {
            throw new Error('Registration failed, please try again!');
        }

        const data = await response.data;

        if (data) {
            return true;
        } else {
            throw new Error('Registration failed, please try again!');
        }
    } catch (error) {
        throw error.message;
    }
};


export const logout = () => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
};

export const isAuthenticated = () => {
    return localStorage.getItem('isAuthenticated') === 'true';
};
