import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    height: 20%;
    width: 100%;

    form {
        width: 250px;
    }
`

export const ListOfPdf = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 17px;
    overflow-x: auto;
    height: 100%;
    width: 100%;
    white-space: nowrap;
`;

export const PdfContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    max-width: 120px;
    height: 120px;
    background-color: white;
    border-radius: 12px;
    cursor: pointer;

    p {
        color: #000;
    }
`;
