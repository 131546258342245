import React, {useContext, useEffect, useState} from "react";
import {Section, Wrapper} from "./DoctorProfile.styles";
import {UserContext} from '../../context/UserContext';
import Input from "../Input";
import AddClientImage from "../AddClientImage";
import {getDoctor} from "../../utils/api-lists/doctor.api";

const DoctorProfile = () => {
    const userContext = useContext(UserContext);
    const [doctor, setDoctor] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const user = userContext.user;
        if (user === null) return;
        console.log("USER", user);

        getDoctor(user.id).then((data) => {
            setDoctor(data);
        }).catch((err) => {
            setError(err);
        });
    }, [userContext.user]);

    if (error) {
        return <p style={{color: 'red'}}>{error}</p>;
    }

    if (!doctor) {
        return <p>Loading...</p>;
    }

    return (
        <Wrapper>
            <Section>
                <h4>Profile photo:</h4>
                <div>
                    <AddClientImage showText={false}/>
                </div>
            </Section>
            <Section>
                <h4>Organization</h4>
                <Input type="text" name="organization" placeholder='Organization*' disabled={true}/>
            </Section>
            <Section>
                <h4>Name</h4>
                <Input type="text" name="name" value={doctor.first_name} placeholder='First Name' disabled={true}/>
            </Section>
            <Section>
                <h4>Surname</h4>
                <Input type="text" name="last_name" value={doctor.last_name} placeholder='Last Name' disabled={true}/>
            </Section>
            <Section>
                <h4>E-mail adress</h4>
                <Input type="email" name="email" value={doctor.email} placeholder='email@mail.com' disabled={true}/>
            </Section>
            <Section>
                <h4>Password</h4>
                <button onClick={() => {
                }} type="submit" className="btn btn-2 btn-align-right">Change password
                </button>
            </Section>
        </Wrapper>
    )
};

export default DoctorProfile;
