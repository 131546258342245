import {GlobalStyle} from './GlobalStyle';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ProtectedRoute from './utils/ProtectedRoute';
import GuestRoute from './utils/GuestRoute';

//Pages
import JoinUsPage from './pages/JoinUsPage';
import HomePage from './pages/HomePage';
import HelpPage from './pages/HelpPage';
import LoginPage from './pages/LoginPage';
import Signup1Page from './pages/Signup1Page';
import Signup2Page from './pages/Signup2Page';
import EmailVerificationPage from './pages/EmailVerificationPage';
import NewPatientPage from './pages/NewPatientPage';
import PatientListPage from './pages/PatientListPage';
import PatientsCabinetPage from './pages/PatientCabinet/PatientsCabinetPage';
import NewTestPage from './pages/NewTestPage';
import PdfPage from './pages/PatientCabinet/PdfPage';
import SelectPatientPage from './pages/SelectPatientPage';
import VideoAnalysisPage from './pages/VideoAnalysisPage';
import DoctorProfilePage from './pages/DoctorProfilePage';
import Layout from "./components/Layout";
import VisualAnalysis from "./pages/PatientCabinet/FileManagement/VisualAnalysis";
import FileUploadPage from "./pages/PatientCabinet/FileManagement/FileUploadPage";
import {Toaster} from "react-hot-toast";
import CompareFilesPage from "./pages/PatientCabinet/FileManagement/CompareFiles.page";
import PdfView from "./pages/PatientCabinet/PdfView";

function App() {
    return (
        <Router>
            <GlobalStyle/>
            <Layout>
                <Routes>
                    <Route path='/' element={<GuestRoute> <JoinUsPage/> </GuestRoute>}/>
                    <Route path='/login' element={<GuestRoute> <LoginPage/> </GuestRoute>}/>
                    <Route path='/verify' element={<GuestRoute> <EmailVerificationPage/> </GuestRoute>}/>
                    <Route path='/sign-up' element={<GuestRoute> <Signup1Page/> </GuestRoute>}/>
                    <Route path='/sign-up-next/:role' element={<GuestRoute> <Signup2Page/> </GuestRoute>}/>

                    <Route path='/dashboard' element={<ProtectedRoute> <HomePage/> </ProtectedRoute>}/>
                    <Route path='/help-page' element={<ProtectedRoute> <HelpPage/> </ProtectedRoute>}/>
                    <Route path='/new-patient' element={<ProtectedRoute> <NewPatientPage/> </ProtectedRoute>}/>
                    <Route path='/patient-list' element={<ProtectedRoute> <PatientListPage/> </ProtectedRoute>}/>
                    <Route path='patient-cabinet/:patientId' element={<ProtectedRoute/>}>
                        <Route path='' element={<PatientsCabinetPage/>}/>
                        <Route path='skin-report'>
                            <Route path='' element={<PdfPage/>}/>
                            <Route path=':pdfid' element={<PdfView/>}/>
                        </Route>
                        <Route path='analysis'>
                            <Route path='' element={<VisualAnalysis/>}/>
                            <Route path='file-upload' element={<FileUploadPage/>}/>
                            <Route path='compare' element={<CompareFilesPage/>}/>
                        </Route>
                    </Route>
                    <Route path='/new-test' element={<ProtectedRoute> <NewTestPage/> </ProtectedRoute>}/>
                    <Route path='/pdf/:patientId' element={<ProtectedRoute> <PdfPage/> </ProtectedRoute>}/>
                    <Route path='/select-patient' element={<ProtectedRoute> <SelectPatientPage/> </ProtectedRoute>}/>
                    <Route path='/analysis' element={<ProtectedRoute> <VideoAnalysisPage/> </ProtectedRoute>}/>
                    <Route path='/profile' element={<ProtectedRoute> <DoctorProfilePage/> </ProtectedRoute>}/>
                </Routes>
                <Toaster position="top-right"/>
            </Layout>
        </Router>
    );
}

export default App;
