import React, {useEffect, useState} from 'react';

// Components
import {DashboardWrapper} from '../../../components/DashboardWrapper/DashboardWrapper.styles';
import {FaArrowLeft, FaRegTrashAlt} from "react-icons/fa";
import {useNavigate, useParams} from "react-router-dom";
import {IconContext} from "react-icons";
import {Header, ImageField, Images, StyledModal} from "../styles";
import CircleButton from "../../../widgets/AddIcon";
import {MdCompare} from "react-icons/md";
import {deletePatientFile, getFile, getPatientFilesList} from "../../../utils/api-lists";
import toast from "react-hot-toast";
import {IoMdRefresh} from "react-icons/io";
import {filterFileName} from "../../../utils/general.util";
import {FaXmark} from "react-icons/fa6";
import {DivRow} from "../../../widgets/GeneralBody";

const PatientsCabinetPage = () => {
    const {patientId} = useParams();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isCompare, setIsCompare] = useState(false);

    const [filesToCompare, setFilesToCompare] = useState([]);


    function toggleModal(file) {
        setSelectedFile(file);
        setIsOpen(!isOpen);
    }

    function getPatientFiles() {
        if (isLoading) return;
        setIsLoading(true);
        toast.promise(
            getPatientFilesList(patientId), {
                loading: 'Loading...',
                success: 'Loaded successfully!',
                error: (e) => `Failed to get files: ${e.response.data.message}`
            }
        )
            .then(r => {
                setFiles(r.data);
            })
            .catch(e => {
            })
            .finally(() => {

                setIsLoading(false);
            });
    }

    function deleteFile() {
        if (isLoading) return;
        setIsLoading(true);
        toast.promise(
            deletePatientFile(patientId, selectedFile['id']), {
                loading: 'Deleting...',
                success: 'Deleted successfully!',
                error: (e) => `Failed to delete file: ${e.response.data.message}`
            }
        )
            .catch(e => {
                console.error(e);
            })
            .finally(() => {
                setIsOpen(false);
                setIsLoading(false);
                getPatientFiles();
            })
    }

    function compareFiles() {
        console.log(filesToCompare);
        if (isCompare) {
            navigate({
                pathname: `compare`,
                search: `?file1Id=${filesToCompare[0]}&file2Id=${selectedFile['id']}`
            });
            return;
        }

        setFilesToCompare([...filesToCompare, selectedFile['id']]);
        setIsCompare(true);
        setIsOpen(false);
    }

    function clearCompare() {
        setSelectedFile([]);
        setIsCompare(false);
    }


    useEffect(() => {
        document.title = 'Visual analysis';
    }, []);

    useEffect(() => {
        getPatientFiles();
    }, [patientId]);

    const ImageWrapper = ({file}) => {
        return (
            <ImageField onClick={() => toggleModal(file)}>
                <img src={getFile(file['image_name'])} alt="Image"/>
                <p>{filterFileName(file['image_name'])}</p>
            </ImageField>
        )
    }

    const VideoWrapper = ({file}) => {
        return (
            <ImageField onClick={() => toggleModal(file)}>
                <video controls>
                    <source src={getFile(file['image_name'])} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                <p>{filterFileName(file['image_name'])}</p>
            </ImageField>
        )
    }

    const ShowFiles = () => {
        return (
            files.map((file, index) => {
                switch (file['type']) {
                    case 'video':
                        return VideoWrapper({file});
                    default:
                    case 'image':
                        return ImageWrapper({file});
                }
            })
        )
    }

    const showWithFileType = () => {
        switch (selectedFile['type']) {
            case 'video':
                return (
                    <video controls>
                        <source src={getFile(selectedFile['image_name'])} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                );
            default:
            case 'image':
                return <img src={getFile(selectedFile['image_name'])} alt="Image"/>;
        }
    }

    return (
        <DashboardWrapper>
            <Header>
                <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
                    <FaArrowLeft onClick={() => navigate(-1)}/>
                </IconContext.Provider>
                <h3>Visual Analysis</h3>

                <CircleButton onClick={() => {
                    getPatientFiles();
                }}>
                    <IoMdRefresh/>
                </CircleButton>
                <CircleButton onClick={() => {
                    navigate('file-upload');
                }}/>
            </Header>
            {
                isCompare &&
                <DivRow maxWidth={320} onClick={clearCompare}>
                    <h4>Select file to Compare:</h4>
                    <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
                        <FaXmark/>
                    </IconContext.Provider>
                </DivRow>
            }
            <Images>
                {
                    ShowFiles()
                }
            </Images>
            {
                selectedFile &&
                <StyledModal
                    isOpen={isOpen}
                    onBackgroundClick={toggleModal}
                    onEscapeKeydown={toggleModal}>
                    {selectedFile && showWithFileType({selectedFile})}
                    <div className="bottomModal">
                        <p>{filterFileName(selectedFile['image_name'])}</p>
                        <div className="modalButtonGroup">
                            <CircleButton onClick={compareFiles}>
                                <MdCompare/>
                            </CircleButton>
                            <CircleButton onClick={() => deleteFile()} disabled={isLoading}>
                                <FaRegTrashAlt/>
                            </CircleButton>
                        </div>
                    </div>
                </StyledModal>
            }
        </DashboardWrapper>
    );

}

export default PatientsCabinetPage;
